import { TermItemDefinition } from '@/services/term'
import { showError } from '@/services/axios'
import { app, apiURL } from '@/main'
import { defineStore } from 'pinia'

export class ContactAttribute {
  id = '' // path without permission, without language, eg : /processingform, or /marketedMilks/findfarmermilk
  nameDe = '' // read | write | anything
  nameFr = '' // read | write | anything
  nameIt = '' // read | write | anything
  value = ''
}

export const useSearchContactAttributesRelationsStore = defineStore('SearchContactAttributesRelationsStore', {
  state: () => ({
    ContactAttribute: [] as ContactAttribute[],
    contactParentId: null as number | null,
    useTerms: true,
    items: [
      { key: 'contactParentId', operation: ':' },
      {
        key: 'ContactAttribute',
        operation: ':',
        itemType: 'objects'
      }
    ] as TermItemDefinition[]
  }),
  actions: {
    async fetchContactAttributes(forced: boolean | undefined) {
      if (this.ContactAttribute.length === 0 || forced === true) {
        console.log('fetched: SearchContactAttributesRelationsStore')
        try {
          const result = await app.axios.get(apiURL + '/contactAttributes/search')
          result.data.map((item: ContactAttribute) => (item.value = ''))
          this.ContactAttribute = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
